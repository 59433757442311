import WrinkleBefore from '../../assets/images/WrinkleManipulation-Bf.jpg';
import WrinkleAfter from '../../assets/images/WrinkleManipulation-Af.jpg';
import ImageSharpBefore from '../../assets/images/ImageSharpening-Bf.jpg';
import ImageSharpAfter from '../../assets/images/ImageSharpening-Af.jpg';
import AlphaBlendBefore from '../../assets/images/AlphaBlending-Bf.jpg';
import AlphaBlendAfter from '../../assets/images/AlphaBlending-Af.jpg';
import ShadowBefore from '../../assets/images/ProductShadowExtraction-Bf.jpg';
import ShadowAfter from '../../assets/images/ProductShadowExtraction-Af.jpg';
import DustRemovalBefore from '../../assets/images/DustRemoval-Bf.jpg';
import DustRemovalAfter from '../../assets/images/DustRemoval-Af.jpg';
import ColorMatchingBefore from '../../assets/images/ColorMatching-Bf.jpg';
import ColorMatchingAfter from '../../assets/images/ColorMatching-Af.jpg';

const data =[
        {
            "id": "e1",
            "title": "Wrinkle Manipulation",
            "description": "Automatically iron out wrinkles from your garment product images based on your",
            "firstImage": WrinkleBefore,
            "secondImage": WrinkleAfter,
        },
        {
            "id": "e2",
            "title": "Image Sharpening",
            "description": "Draw attention to the small details of your products by automatically sharpening your",
            "firstImage": ImageSharpBefore,
            "secondImage": ImageSharpAfter,
        },
        {
            "id": "e3",
            "title": "Alpha Blending",
            "description": "Automatically create the appearance of your products in partial or complete transparency. Allow AI to.",
            "firstImage": AlphaBlendBefore,
            "secondImage": AlphaBlendAfter,
        },
        {
            "id": "e4",
            "title": "Product Shadow Extraction & Manipulation",
            "description": "Elevate your product images from amateur to professional by using",
            "firstImage": ShadowBefore,
            "secondImage": ShadowAfter,
        },
        {
            "id": "e5",
            "title": "Dust Removal",
            "description": "Automatically remove stains and spots from your product images while retaining the natural shadows",
            "firstImage": DustRemovalBefore,
            "secondImage": DustRemovalAfter,
        },
        {
            "id": "e6",
            "title": "Colour Matching",
            "description": "Automatically match the color of the product image with the physical sample. Reduce the number of",
            "firstImage": ColorMatchingBefore,
            "secondImage": ColorMatchingAfter,
        }
        
    ]

export default data;