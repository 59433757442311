import GarmentClippingBefore from '../../assets/images/GarmentClipping-Bf.jpg';
import GarmentClippingAfter from '../../assets/images/GarmentClipping-Af.jpg';
import ProductClipperBefore from '../../assets/images/ProductClipper-Bf.jpg';
import ProductClipperAfter from '../../assets/images/ProductClipper-Af.jpg';
import ModelClipperBefore from '../../assets/images/ModelClipper-Bf.jpg';
import ModelClipperAfter from '../../assets/images/ModelClipper-Af.jpg';
import RoiBefore from '../../assets/images/ROI-Bf.jpg';
import RoiAfter from '../../assets/images/ROI-Af.jpg'

const Data = [
    {
        "id": "c1",
        "title": "Garment Clipper",
        "description": "Automatically clip out the background and mannequin from your garment product images in bulk",
        "firstImage": GarmentClippingBefore,
        "secondImage": GarmentClippingAfter,
    },
    {
        "id": "c2",
        "title": "Product Clipper",
        "description": "Automatically clip out the background from your product images without removing",
        "firstImage": ProductClipperBefore,
        "secondImage": ProductClipperAfter,
    },
    {
        "id": "c3",
        "title": "Model Clipper",
        "description": "Remove the background of your on-model eCommerce images while retaining every minute detail of garment",
        "firstImage": ModelClipperBefore,
        "secondImage": ModelClipperAfter,

    },
    {
        "id": "c4",
        "title": "ROI Cropping",
        "description": "Leverage AI to automatically crop your on-model garment images while maintaining the",
        "firstImage": RoiBefore,
        "secondImage": RoiAfter,
    }

]

export default Data;