import ProductAlignBefore from '../../assets/images/ProductAlignment-Bf.jpg';
import ProductAlignAfter from '../../assets/images/ProductAlignment-Af.jpg';
import ProductSuperImposeBefore from '../../assets/images/ProductSuperimpositions-Bf.jpg';
import ProductSuperImposeAfter from '../../assets/images/ProductSuperimpositions-Af.jpg'
import RotationCorrectionBefore from '../../assets/images/RotationCorrection-Bf.jpg';
import RotationCorrectionAfter from '../../assets/images/RotationCorrection-Af.jpg'
import ProductShapeBefore from '../../assets/images/ProductShapeCorrection-Bf.jpg';
import ProductShapeAfter from '../../assets/images/ProductShapeCorrection-Af.jpg'

const Data=[
    {
        "id": "s1",
        "title": "Product Alignment & Resize",
        "description": "Align your asymmetrical and uneven product images to make",
        "firstImage": ProductAlignBefore,
        "secondImage": ProductAlignAfter,
    },
    {
        "id": "s2",
        "title": "Product Superimposition",
        "description": "Leverage AI to match the height of your product sets while",
        "firstImage": ProductSuperImposeBefore,
        "secondImage": ProductSuperImposeAfter,
    },
    {
        "id": "s3",
        "title": "Rotation Correction",
        "description": "Automatically rotate your garment products in bulk as per your brand style guide. Make",
        "firstImage": RotationCorrectionBefore,
        "secondImage": RotationCorrectionAfter,
    },
    {
        "id": "s4",
        "title": "Product Shape Correction",
        "description": "Leverage our proprietary AI technology to fix the angle and shape of your eCommerce products instantly.",
        "firstImage": ProductShapeBefore,
        "secondImage": ProductShapeAfter,
    },
]

export default Data;