import React, { useEffect, useState } from "react";
import "./Header.scss";
import headerLogo from "../../../src/assets/images/header_logo.svg";
import { Button } from "antd";
import sandwitchIcon from "../../../src/assets/images/sandwitch_icon.svg";
import SiteHeader from "../siteHeader/SiteHeader";

const Header = ({ setBookingFlag }) => {
  const [mobileView, setMobileView] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 991) {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const mobileViewContent = () => {
    setMobileView(!mobileView);
  };

  const navigateHeaderTab = (id) => {
    const target = document.getElementById(id);
    let position = target?.getBoundingClientRect();
    window.scrollTo({
      top: position?.top + window.scrollY - 115,
      behavior: "smooth",
    });
  };

  return (
    <>
      <header>
        <SiteHeader />
        <div className="header_container">
          <div className="header_logo">
            <img src={headerLogo} alt="header_logo" className="header_icon" />
          </div>
          <div className="header_content">
            <div className="header_subContent">
              {/* <Button className="text_button" type="text">
                <span className="small_text"> Home</span>
              </Button> */}
              <Button
                className="text_button"
                type="text"
                onClick={() => navigateHeaderTab("aiTools")}
              >
                <span className="small_text">APIs</span>
              </Button>
              <Button
                className="text_button"
                type="text"
                onClick={() => navigateHeaderTab("howItsWork")}
              >
                <span className="small_text">How it Works</span>
              </Button>
              <Button
                className="text_button"
                type="text"
                onClick={() => setBookingFlag(true)}
              >
                <span className="small_text">Pricing</span>
              </Button>
            </div>

            <Button className="try_btn" onClick={() => setBookingFlag(true)}>
              Talk to an Expert
            </Button>
            <div className="sandwitch_icon" onClick={mobileViewContent}>
              <img src={sandwitchIcon} alt="" />
            </div>
          </div>
        </div>
        {mobileView && (
          <div className="mobile_view">
            <Button className="text_button" type="text">
              <span className="small_text"> Home</span>
            </Button>
            <Button className="text_button" type="text">
              <span className="small_text">Platform</span>
            </Button>
            <Button className="text_button" type="text">
              <span className="small_text">APIs</span>
            </Button>
            <Button className="text_button" type="text">
              <span className="small_text">Pricing</span>
            </Button>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
