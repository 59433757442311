import { Button } from "antd";
import React from "react";
import "./Banner.scss";
import ReactPlayer from "react-player";
const Banner = ({ setBookingFlag }) => {
  return (
    <section className="banner_container">
      <div className="banner_text">
        <h2>
          AI Powered Tools & Workflows{" "}
          <span className="sub_text"> For Image Editing</span>
        </h2>
      </div>
      <div className="banner_subText">
        <span>
          Transform your <b>Fashion image editing </b> process with fast,
          efficient, and scalable AI editing tools & workflows accessible right
          from your browser or APIs.
        </span>
      </div>
      <div className="content_spacing">
        <Button className="free_btn" onClick={() => setBookingFlag(true)}>
          Try for free
        </Button>
        {/* <span className="credit_text">No Credit Card required</span> */}
      </div>
      <div className="banner_video">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=myDmqQ10q50"
          playing={true}
          width="100%"
          height="100%"
          muted={true}
          className="react-player"

          // playIcon={<button>Play</button>}
          // light="https://i.stack.imgur.com/zw9Iz.png"
        />
      </div>
    </section>
  );
};

export default Banner;
