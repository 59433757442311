import { useState } from "react";
import { Typography, Card, Space, Button, Row, Col } from "antd";

import BeforeAfterSlider from "../../Common/beforeAfter";

import EditingData from "../../Common/AiToolsData/editing.js";
import ClipCropData from "../../Common/AiToolsData/cropAndClip.js";
import ShapeSizeData from "../../Common/AiToolsData/shapeAndSize.js";
import WorkFlowData from "../../Common/AiToolsData/workflows.js";
import "./style.scss";

const AiTool = () => {
  const [tabContent, setTabContent] = useState({ index: 3, data: EditingData });

  const changeTab = (index) => {
    if (index == 1) {
      setTabContent({
        index: index,
        data: ClipCropData,
      });
    } else if (index == 2) {
      setTabContent({
        index: index,
        data: ShapeSizeData,
      });
    } else if (index == 3) {
      setTabContent({
        index: index,
        data: EditingData,
      });
    } else if (index == 4) {
      setTabContent({
        index: index,
        data: WorkFlowData,
      });
    }
  };

  return (
    <div className="aiToolRoot">
      <Typography.Title className="title" level={1}>
        AI Tools
      </Typography.Title>
      <Typography.Paragraph className="titleDesc" level={1}>
        Our AI-powered editing tools and workflows are specifically{" "}
        <span className="boldClass">
          designed to meet the unique
          <br />
          needs of garment and fashion catalog image editing.
        </span>{" "}
        Depending on your editing
        <br />
        requirements you can club any tool and create workflows specific to your
        use cases
      </Typography.Paragraph>

      <div className="buttonSpace">
        <span
          onClick={(e) => changeTab(1)}
          className={tabContent.index == 1 ? "selectedtab" : "tabButton"}
        >
          Crop & Clip
        </span>
        <span
          onClick={(e) => changeTab(2)}
          className={tabContent.index == 2 ? "selectedtab" : "tabButton"}
        >
          Shape, Size & Alignment
        </span>
        <span
          onClick={(e) => changeTab(3)}
          className={tabContent.index == 3 ? "selectedtab" : "tabButton"}
        >
          Editing
        </span>
        <span
          onClick={(e) => changeTab(4)}
          className={tabContent.index == 4 ? "selectedtab" : "tabButton"}
        >
          Workflows
        </span>
      </div>
      <div className="tabContent">
           {tabContent.data.map((item, index) => {
            console.log(item);
            return (
              <div span={8} key={index}>
                <BeforeAfterSlider
                  key={item.id}
                  firstImage={item.firstImage}
                  secondImage={item.secondImage}
                  title={item.title}
                  desc={item.description}
                ></BeforeAfterSlider>
              </div>
            );
          })}
       </div>
    </div>
  );
};

export default AiTool;
