import ScaleShotBefore from '../../assets/images/ScaleShotCreation-Bf.jpg';
import ScaleShotAfter from '../../assets/images/ScaleShotCreation-Af.jpg';
import ComboGenerationBefore from '../../assets/images/ComboGeneration-Bf.jpg'
import ComboGenerationAfter from '../../assets/images/ComboGeneration-Af.jpg'
import ShapeTpsBefore from '../../assets/images/ShapeTPS-Bf.jpg';
import ShapeTpsAfter from '../../assets/images/ShapeTPS-Af.jpg';
import MannequinGhostBefore from '../../assets/images/MannequinGhost-Bf.jpg';
import MannequinGhostAfter from '../../assets/images/MannequinGhost-Af.jpg';    
import ModelGhostBefore from '../../assets/images/ModelGhost-Bf.jpg';
import ModelGhostAfter from '../../assets/images/ModelGhost-Af.jpg';
const Data = [
    { 
        "id": "w1",
        "title": "Mannequin to Ghost Image",
        "description": "Automatically remove the mannequins from the background while giving a professional touch to your",
        "firstImage": MannequinGhostBefore,
        "secondImage": MannequinGhostAfter,
    },
    {
        "id": "w2",
        "title": "On Model to Ghost Image ",
        "description": "Create composite product images at the click of a mouse. Have the flexibility to choose and change",
        "firstImage": ModelGhostBefore,
        "secondImage": ModelGhostAfter
        

    },
    {
        "id": "w3",
        "title": "Scale Shot Creation",
        "description": "Give your customers a better idea of the actual size of the product by automatically creating a scale shot.",
        "firstImage": ScaleShotBefore,
        "secondImage": ScaleShotAfter,
    },
    {
        "id": "w4",
        "title": "Combo Generation",
        "description": "Create composite product images at the click of a mouse. Have the flexibility to choose and change",
        "firstImage": ComboGenerationBefore,
        "secondImage": ComboGenerationAfter,
    },
    {
        "id": "w5",
        "title": "Garment Shape Correction",
        "description": "Create the best user experience by automatically aligning your garment products in just a few minutes.",
        "firstImage": ShapeTpsBefore,
        "secondImage": ShapeTpsAfter,
    }
]

export default Data;    