import AppLayout from "../Common/Layout";

export const routesList = [
  {
    path: `/`,
    title: "",
    exact: true,
    protected: true,
    component: AppLayout,
  },
  {
  	path: "/talkToAnExpert",
  	title: "",
  	exact: true,
  	protected: true,
  	component: AppLayout,
  },
];
