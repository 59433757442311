import React, { useEffect, useState } from "react";
import Banner from "../../Components/Banner";
import BrandCustomer from "../../Components/BrandCustomer";
import Header from "../Header";
import HowItWorkPage from "../../Components/howItWorkPage";
import BestSuitedFor from "../../Components/bestSuitedFor";
import Footer from "../Footer";
import AiTool from "../../Components/aiTool";
import SiteHeader from "../siteHeader/SiteHeader";
import DynamicModal from "../DynamicModal/DynamicModal";
import BookingForm from "../BookingForm/BookingForm";
import { Layout, Space } from "antd";
import { useLocation } from "react-router-dom";
const { Content } = Layout;

const LandingWrapper = (props) => {
  const [bookingFlag, setBookingFlag] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/talkToAnExpert") {
      setBookingFlag(true);
    }
  }, []);

  return (
    <div>
      <Header setBookingFlag={setBookingFlag} bookingFlag={bookingFlag} />
      <Content>
        <Banner setBookingFlag={setBookingFlag} />
        <BrandCustomer />
        <div id="aiTools">
          <AiTool />
        </div>
        <div id="howItsWork">
          <HowItWorkPage />
        </div>
        <BestSuitedFor />
        <Footer />
      </Content>
      {bookingFlag && (
        <DynamicModal
          open={bookingFlag}
          closable={true}
          close={() => setBookingFlag(false)}
          footer={null}
          width={1200}
          // title={`Model Occasions: ${props.name}`}
          component={<BookingForm setBookingFlag={setBookingFlag} />}
        />
      )}
    </div>
  );
};

export default LandingWrapper;
