 import "./App.scss";
// import AppLayout from "./Common/Layout";
import "./assets/scss/style.scss";
import Router from "./routes";
 
function App() {
  return (
    <>
    <Router/>
    {/* <h1>ihfjroijpro</h1> */}
     {/* <AppLayout/> */}
    </>
  );
}

export default App;
