import React, { useState } from "react";
import "./style.scss";
import { Typography, Card } from "antd";
import ReactPlayer from "react-player";

const HowItWorkMobile = () => {
  const [cardindex, setCardIndex] = useState(1);
  const [videoUrl, setVideoUrl] = useState(
    "https://www.youtube.com/watch?v=v76uz3L5mI8"
  );
  const handleCardClick = (index, url) => {
    setCardIndex(index);
    setVideoUrl(url);
  };

  return (
    <div className="cardsSection">
      <div>
        <Card
          className={cardindex == 1 ? "card cardSelected" : "card"}
          bordered={false}
          onClick={(e) =>
            handleCardClick(1, "https://www.youtube.com/watch?v=v76uz3L5mI8")
          }
        >
          <Typography.Title
            className={
              cardindex == 1 ? "cardTitle cardTitleSelected" : "cardTitle"
            }
            level={5}
          >
            Individual Tools
          </Typography.Title>
          <Typography.Paragraph className="cardBody">
            Best suited to do tasks like clipping, Dust removal, Image enhancer
          </Typography.Paragraph>
        </Card>
        {cardindex == 1 && (
          <div className="videoContent">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              className="video"
              width="100%"
              height="100%"
              muted={true}
              // className="react-player"
              // playIcon={<button>Play</button>}
              // light="https://i.stack.imgur.com/zw9Iz.png"
            />
            {/* <video className="video" src={video} controls></video> */}
          </div>
        )}
      </div>
      <div>
        <Card
          className={cardindex == 2 ? "card cardSelected" : "card"}
          bordered={false}
          onClick={(e) =>
            handleCardClick(2, "https://www.youtube.com/watch?v=dnb5dLQHmTQ")
          }
        >
          <Typography.Title
            className={
              cardindex == 2 ? "cardTitle cardTitleSelected" : "cardTitle"
            }
            level={5}
          >
            Preset Workflows
          </Typography.Title>
          <Typography.Paragraph className="cardBody">
            Best suited to do tasks like clipping, Dust removal, Image enhancer
          </Typography.Paragraph>
        </Card>
        {cardindex == 2 && (
          <div className="videoContent">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              className="video"
              width="100%"
              height="100%"
              muted={true}
            />
          </div>
        )}
      </div>
      <div>
        <Card
          className={cardindex == 3 ? "card cardSelected" : "card"}
          bordered={false}
          onClick={(e) =>
            handleCardClick(3, "https://www.youtube.com/watch?v=5oeElciYXyI")
          }
        >
          <Typography.Title
            className={
              cardindex == 3 ? "cardTitle cardTitleSelected" : "cardTitle"
            }
            level={5}
          >
            Custom Workflow Creation
          </Typography.Title>
          <Typography.Paragraph className="cardBody">
            Best suited to do tasks like clipping, Dust removal, Image enhancer
          </Typography.Paragraph>
        </Card>
        {cardindex == 3 && (
          <div className="videoContent">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              // controls={true}
              className="video"
              width="100%"
              height="100%"
              muted={true}
              // className="react-player"
              // playIcon={<button>Play</button>}
              // light="https://i.stack.imgur.com/zw9Iz.png"
            />
            {/* <video className="video" src={video} controls></video> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default HowItWorkMobile;
