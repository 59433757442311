import React from "react";
import { Routes, Route } from "react-router-dom";
import { routesList } from "./pageRoutes";

const Router = () => {
  return (
    <Routes>
      {routesList.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            element={
              route.provider ? (
                <route.provider>
                  <route.component />
                </route.provider>
              ) : (
                <route.component />
              )
            }
          >
            {/* {route.children &&
              route.children.map((child, i) => {
                return (
                  <Route
                    key={i}
                    path={child.path}
                    element={<child.component />}
                  />
                );
              })} */}
          </Route>
        );
      })}
    </Routes>
  );
};

export default Router;
