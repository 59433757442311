import { Typography } from "antd";
import React from "react";
import "./Footer.scss";
import headerLogo from "../../../src/assets/images/header_logo.svg";
import email from "../../../src/assets/images/email.png";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="contact_part">
        <div className="contact_title">
          <Typography.Title className="title_legend">
            Use Image Edit tools and workflows to cut down your operations cost
            drastically
          </Typography.Title>
        </div>
        <div className="contact_email">
          <Typography.Title
            style={{
              fontSize: "16px",
              color: "#ffffff",
            }}
          >
            Contact Us to Know More
          </Typography.Title>
          <div className="compact_button">
            <input placeholder="Enter email id" />
            <button className="expert_btn">Talk to Expert</button>
          </div>
        </div>
      </div>
      <div className="address_part">
        <div className="company_content">
          <img
            alt=""
            src={headerLogo}
            style={{
              width: "16rem",
            }}
          />
          <div className="content_legend">
            <Typography.Paragraph>
              ImageEdit.ai is a service that will retouch your images, tweak
              elements that ruin the aesthetics of an image, to give you a
              beautiful, flawless end product.{" "}
            </Typography.Paragraph>
          </div>
          <div className="bottom-shade-line-mobile">
            <div className="email_id">
              <img src={email} alt="" />
              <span
                style={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.8)",
                  fontWeight: "700",
                }}
              >
                sales@imageedit.ai
              </span>
            </div>
          </div>
        </div>
        <div className="company_addressOne">
          <div className="address_legend">
            <Typography.Title
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: "700",
              }}
            >
              LosAngeles
            </Typography.Title>
            <Typography.Paragraph
              style={{
                width: "78%",
              }}
              className="address_text"
            >
              Los Angeles St #703 Los Angeles, CA 90014
            </Typography.Paragraph>
          </div>
          <div className="address_legend">
            <Typography.Title
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: "700",
              }}
            >
              New York
            </Typography.Title>
            <Typography.Paragraph
              style={{
                width: "50%",
              }}
              className="address_text"
            >
              38th St Suite 203 New York, NY 10018{" "}
            </Typography.Paragraph>
          </div>
          <div className="address_legend">
            <Typography.Title
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: "700",
              }}
            >
              Germany
            </Typography.Title>
            <Typography.Paragraph className="address_text">
              Breslauer Straße 6, 41460 Neuss
            </Typography.Paragraph>
          </div>
        </div>
        <div className="corporate_Address">
          <div className="address_legend">
            <Typography.Title
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: "700",
              }}
            >
              Corporate Office 
            </Typography.Title>
            <Typography.Paragraph
              className="address_text"
              style={{
                marginBottom: "0",
              }}
            >
              Pendley Road,
            </Typography.Paragraph>
            <Typography.Paragraph className="address_text">
              Suite B Cumming, GA 30041
            </Typography.Paragraph>
          </div>
          <div className="address_legend">
            <Typography.Title
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.7216)",
                fontWeight: "700",
              }}
            >
              Corporate Office
            </Typography.Title>
            <Typography.Paragraph className="address_text">
              Sec 44, Gurgaon, India, 122003
            </Typography.Paragraph>
          </div>
        </div>
        <div
          className="mobile_copyright"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="bottom-shade-line-mobile"></div>
          <span
            style={{
              padding: "1rem 0",
            }}
          >
            © Imageedit.ai 2023 | All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
