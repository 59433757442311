import { Typography, Card } from "antd";
import "./style.scss";
import cardImage1 from "../../assets/images/bestSuitedFor_1.png";
import cardImage2 from "../../assets/images/bestSuitedFor_2.png";
import cardImage3 from "../../assets/images/bestSuitedFor_3.png";
import start from "../../assets/images/start.png";
import sell from "../../assets/images/sell.png";
import edit from "../../assets/images/edit.png";
const BestSuitedFor = () => {
  return (
    <div className="bestSuitedForRoot">
      <Typography.Title className="title" level={1}>
        Best <span style={{ color: "#DE5954" }} className="title">Suited for.</span>
      </Typography.Title>
      <div className="cardSection">
        <Card className="card" cover={<img alt="example" src={cardImage1} />}>
          <Typography.Title className="cardTitle" level={4}>
            Image Editing Houses
          </Typography.Title>
          <Typography.Paragraph className="cardBody paragraph_text">
            Editing houses can get more work done with the same number of
            editors. Remain competitive in price, and deliver high-quality
            editing services to clients in a timely and cost-effective manner.
          </Typography.Paragraph>
        </Card>
        <Card className="card" cover={<img alt="example" src={cardImage2} />}>
          <Typography.Title  className="cardTitle" level={4}>
            Photographers
          </Typography.Title>
          <Typography.Paragraph className="cardBody">
            By providing an end-to-end service, Photographers can offer a more
            complete and efficient solution to their clients, improving their
            overall value proposition.
          </Typography.Paragraph>
        </Card>
        <Card className="card" cover={<img alt="example" src={cardImage3} />}>
          <Typography.Title  className="cardTitle" level={4}>
            Fashion Brands
          </Typography.Title>
          <Typography.Paragraph className="cardBody">
            Fashion Brands can improve their productivity, reduce costs, and
            maintain control over their operations, while delivering
            high-quality content to customers.
          </Typography.Paragraph>
        </Card>
      </div>
      <div className="featureSection">
        <div className="flexAlign-center">
          <Typography.Title className="title" level={1}>
            Transform your image editing process,
            <br></br>
            <span style={{ color: "#DE5954" }} className="title">
              get more work done in less time & with same number of editors.
            </span>
          </Typography.Title>
        </div>
        <div className="featureContent">
          <div
           
            // style={{
            //   borderBottom:'1px',
            //   background:'linear-gradient(to top right, #f87171, rgba(248, 113, 113, 0))',
            // }}
          >
            <div className="featureHeader">
              <img
                src={start}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <div  className="bottom-shade-line">
                <Typography.Title
                  className="small_text fontWeight-800"
                  // style={{
                  //   fontSize: "12px",
                  //   color: "#000000",
                  // }
                >
                  Starting At $0.30
                </Typography.Title>
                <Typography.Paragraph className="paragraph_text">
                  A fully automated editing process helps you reduce your image
                  processing cost by up to 65%.
                </Typography.Paragraph>
              </div>
            </div>
          </div>
          <div
           
          >
            <div className="featureHeader">
              <img
                src={sell}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <div  className="bottom-shade-line"
             >
                <Typography.Title className="small_text fontWeight-800">
                  Focus on selling not creating
                </Typography.Title>
                <Typography.Paragraph className="paragraph_text">
                  With decreased time to web and cost of image generation, you
                  can take your products online faster resulting in more sales
                  and an edge over your competition.
                </Typography.Paragraph>
              </div>
            </div>
          </div>
          <div
            // className=".bottom-shade-line"
            style={
              {
                // borderBottom: "1px solid #DE5954",
              }
            }
          >
            <div className="featureHeader">
              <img
                src={edit}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <div>
                <Typography.Title className="small_text fontWeight-800">
                  As fast as instant editing
                </Typography.Title>
                <Typography.Paragraph className="paragraph_text">
                  With the proprietary parallel image editing technology
                  developed by FlixStock, get your edited imaged within 30 sec
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSuitedFor;
