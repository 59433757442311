import { Typography } from 'antd';
import ReactCompareImage from 'react-compare-image';
import './style.scss';
import firstImage from '../../assets/images/bestSuitedFor_1.png';
import secondImage from '../../assets/images/bestSuitedFor_2.png';

const BeforeAfterSlider = ({firstImage, secondImage, title, desc}) => {
    const FIRST_IMAGE = {
        imageUrl: firstImage
      };
      const SECOND_IMAGE = {
        imageUrl: secondImage
      };
  return (
  <div className="before-after-slider">
    <div className='module'>
        <ReactCompareImage
            className="compareImage"
            leftImage={firstImage}
            rightImage={secondImage}
        >
        </ReactCompareImage>
        <Typography.Title className="cardTitle" level={2}>
            {title}
        </Typography.Title>
        <Typography.Paragraph className="cardBody" >
            {desc}
        </Typography.Paragraph>
    </div>

  </div>
  );
};

export default BeforeAfterSlider;
