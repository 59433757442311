import { useEffect, useState } from "react";
import { Typography, Card } from "antd";
import "./style.scss";
import video from "../../assets/videos/flixstock.mp4";
import ReactPlayer from "react-player";
import HowItWorkMobile from "../HowItWorkMobile";
const HowItWorkPage = () => {
  const [cardindex, setCardIndex] = useState(1);
    const [mobileView, setMobileView] = useState(false);
    const [videoUrl, setVideoUrl] = useState(
      "https://www.youtube.com/watch?v=v76uz3L5mI8"
    );

  const handleResize = () => {
    if (window.innerWidth < 991) {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  

  const handleCardClick = (index, url) => {
    setCardIndex(index);
    setVideoUrl(url);
  };

  return (
    <>
      <div className="howToWorkRoot">
        <Typography.Title className="title" level={1}>
          How It{" "}
          <span style={{ color: "#F87171" }} className="title">
            Works?
          </span>
        </Typography.Title>
        <Typography.Paragraph className="titleDesc" level={1}>
          "With our image editing tools, you can create custom editing
          workflows, or use our pre-set workflows for efficiency.
          <br />
          Combine individual tools for even more powerful image editing
          capabilities. The possibilities are endless."
        </Typography.Paragraph>
        <div className="bodyContent">
          <div className="cardsSection">
            <Card
              className={cardindex == 1 ? "card cardSelected" : "card"}
              bordered={false}
              onClick={(e) =>
                handleCardClick(
                  1,
                  "https://www.youtube.com/watch?v=v76uz3L5mI8"
                )
              }
            >
              <Typography.Title
                className={
                  cardindex == 1 ? "cardTitle cardTitleSelected" : "cardTitle"
                }
                level={5}
              >
                Individual Tools
              </Typography.Title>
              <Typography.Paragraph className="cardBody">
                Best suited to do tasks like clipping, Dust removal, Image
                enhancer
              </Typography.Paragraph>
            </Card>
            <Card
              className={cardindex == 2 ? "card cardSelected" : "card"}
              bordered={false}
              onClick={(e) =>
                handleCardClick(
                  2,
                  "https://www.youtube.com/watch?v=dnb5dLQHmTQ"
                )
              }
            >
              <Typography.Title
                className={
                  cardindex == 2 ? "cardTitle cardTitleSelected" : "cardTitle"
                }
                level={5}
              >
                Preset Workflows
              </Typography.Title>
              <Typography.Paragraph className="cardBody">
                Best suited to do tasks like clipping, Dust removal, Image
                enhancer
              </Typography.Paragraph>
            </Card>
            <Card
              className={cardindex == 3 ? "card cardSelected" : "card"}
              bordered={false}
              onClick={(e) =>
                handleCardClick(
                  3,
                  "https://www.youtube.com/watch?v=5oeElciYXyI"
                )
              }
            >
              <Typography.Title
                className={
                  cardindex == 3 ? "cardTitle cardTitleSelected" : "cardTitle"
                }
                level={5}
              >
                Custom Workflow Creation
              </Typography.Title>
              <Typography.Paragraph className="cardBody">
                Best suited to do tasks like clipping, Dust removal, Image
                enhancer
              </Typography.Paragraph>
            </Card>
          </div>

          <div className="videoContent">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              // controls={true}
              className="video"
              width="100%"
              height="100%"
              muted={true}
              // className="react-player"
              // playIcon={<button>Play</button>}
              // light="https://i.stack.imgur.com/zw9Iz.png"
            />
            {/* <video className="video" src={video} controls></video> */}
          </div>
        </div>
      </div>
      <div className="bodyContentMobile">
        <HowItWorkMobile />
      </div>
    </>
  );
};

export default HowItWorkPage;
