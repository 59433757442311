import React from "react";
import { Modal } from "antd";

const DynamicModal = (props) => {
  console.log(props, "modal props");
  return (
    <Modal
      open={props.open}
      closable={props.closable ? props.closable : false}
      onCancel={props.close}
      footer={null}
      width={props.width}
      title={props.title}
      bodyStyle={{
        height: props.height,
        // overflow: "scroll",
        backgroundColor: "none",
      }}
       style={{
        background: props.backgroundNone ? "transparent" : "",
      }}
    >
      {props.component && props.component}
    </Modal>
  );
};

export default DynamicModal;
