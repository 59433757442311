import { Layout, Space } from "antd";
import React from "react";
import LandingWrapper from "../LandingWrapper/LandingWrapper";

const AppLayout = (props) => {
  return (
    <Layout
      style={{
        background: "white",
      }}
    >
      <LandingWrapper />
    </Layout>
  );
};

export default AppLayout;
